import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

const BoardingPage = function () {
  return (
    <Layout>
      <Seo
        title="Boarding"
        description="When you board at Southern Cross you trust the top quality, full
        care boarding program our facility has to offer."
      />

      {/* Second Section */}
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 ">
        <div className="mx-auto text-base max-w-prose lg:hidden  ">
          <div>
            <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
              Daily turnout
            </h2>
            <h3 className="heading-serif">Boardings</h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-1">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none ">
              <StaticImage
                src="../images/boarding.png"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Southern Cross Boarding"
                loading="eager"
                className="rounded-lg shadow-lg object-cover object-center"
              />
            </div>
          </div>
          <div className="mt-8 lg:mt-0 ">
            <div className="md:max-w-2xl pl-2 lg:p-0 mx-auto hidden lg:grid">
              <h3 className="heading-serif">Boarding</h3>
            </div>
            <div className="mt-5 prose prose-md prose-indigo text-gray-500 mx-auto md:max-w-prose g:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                When you board at Southern Cross you trust the top quality, full
                care boarding program our facility has to offer. Our program
                services include but not limited to:
              </p>

              <ul>
                <li> 24/7 access to hay and water</li>

                <li>
                  2x daily grain feedings – individual feeding programs upon
                  request
                </li>
                <li> 12x12 or 12x16 foot stalls</li>
                <li> Daily stall cleaning included buckets</li>
                <li> Blanketing and booting upon request</li>
                <li> Individual or group turnout</li>
                <li> Daily night check</li>
                <li> Wash stall with hot and cold running water</li>
                <li> 4 sets of cross ties</li>
                <li> Equine exercise program tailored to your horses needs</li>
                <li> Veterinarian on site</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BoardingPage;
